import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import App from './App';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import './index.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00263c'
    },
    secondary: {
      main: '#ff6b00',
      contrastText: '#fff'
    },
    primaryLight: {
      main: '#EAFAFF'
    }
  },
  typography: {
    fontSize: 13
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
         <CssBaseline />
         <App />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);