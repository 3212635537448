const config = require('./config');

const getPolicyPdf = (policyNumber, idToken) => {
    let endpoint = `${config.API_URL}/v1/policies/${policyNumber}/pdf?id=${idToken}`

    return fetch(endpoint).then((response) => {
        if (!response.ok) {
            if (response.status === 401 || response.status === 403) {
                throw new Error('BadAuthentication');
            } else if (response.status === 404) {
                throw new Error('PolicyNotFound');
            } else {
                throw new Error('UnknownError');
            }
        } 

        return response.blob();

        // return response.arrayBuffer().then((data) => {
        //     return Buffer.from(data).toString('base64');
        // });
    });
}
const getPolicyPdfUrl = (policyNumber, idToken) => {
  return `${config.API_URL}/v1/policies/${policyNumber}/pdf?id=${idToken}`
}

const sendNewPolicyLink = (policyNumber, customerEmailAddress, idToken) => {
    let endpoint = `${config.API_URL}/v1/policies/${policyNumber}/send-pdf-url?id=${idToken}`

    return fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify({
            'email_address': customerEmailAddress
        })
    }).then((response) => {
        if (!response.ok) {
            if (response.status === 401 || response.status === 403) {
                throw new Error('BadAuthentication');
            } else if (response.status === 404) {
                throw new Error('PolicyNotFound');
            } else {
                throw new Error('UnknownError');
            }
        } else {
            return response;
        }
    });
}
const getCarrierData = async (policyNumber, idToken) => {
    const response = await fetch(
      `${config.API_URL}/v1/carrier/${policyNumber}?id=${idToken}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return await response.json();
}

export { getCarrierData, sendNewPolicyLink, getPolicyPdf, getPolicyPdfUrl }
