import { useParams, useSearchParams } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import * as jose from 'jose'
import { TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { sendNewPolicyLink, getPolicyPdf } from '../api/policy';
import NotFound from "./NotFound";
import Header from "../component/Header";

const getIdTokenClaims = (idToken) => {
    return jose.decodeJwt(idToken);
}

const tokenIsExpired = (claims) => {
  const currentUTCTime = new Date().getTime();
  const toMs = 1000;
  return claims.exp * toMs < currentUTCTime;
};

const FRIENDLY_ERROR_MESSAGES = {
    'BadAuthentication': 'We couldn\'t find this policy. Do you have the right link?',
    'PolicyNotFound': 'It looks like the link you provided is invalid.',
    'UnknownError': 'Something went wrong on our end. Please try again later.'
}

export default function Policy() {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const [emailAddress, setEmailAddress] = useState();
    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [expiredLink, setExpiredLink] = useState(false);
    const [invalidLink, setInvalidLink] = useState(false);
    const [noPolicyNumber, setNoPolicyNumber] = useState(false);
    const [pdf, setPdf] = useState();

    useEffect(() => {
      if (!searchParams.has("id")) {
        setInvalidLink(true);
      } else {
        const tokenClaims = getIdTokenClaims(searchParams.get("id"));
    
        if (params.policyNumber === undefined) {
            setNoPolicyNumber(true);
        } else if (tokenIsExpired(tokenClaims)) {
            setExpiredLink(true);
        } else {
          if (tokenClaims.hasOwnProperty("resource_location") && tokenClaims.resource_location !== undefined) {
            setPdf(encodeURIComponent(tokenClaims.resource_location));
          } else {
            getPolicyPdf(params.policyNumber.split(".pdf")[0], searchParams.get("id"))
            .then((data) => {
                setPdf(URL.createObjectURL(data));
            })
            .catch((err) => {
                if (err.message === 'BadAuthentication') {
                    setInvalidLink(true);
                } else {
                    setErrorMessage(FRIENDLY_ERROR_MESSAGES[err.message]);
                    setNoPolicyNumber(true);
                }
            });
          }
        }
      }
    }, [params.policyNumber, searchParams]);

    const sendNewEmailLink = () => {
        setErrorMessage(undefined);
        sendNewPolicyLink(params.policyNumber.split(".pdf")[0], emailAddress, searchParams.get("id"))
        .then(() => {
            setSuccess(true);
        })
        .catch((err) => {
            setErrorMessage(FRIENDLY_ERROR_MESSAGES[err.message]);
        });
    }

    if (noPolicyNumber) {
        return <NotFound />;
    }

    if (expiredLink) {
      return (
        <Stack flex={1}>
          <Header />
          <Box display="flex" flex={1} alignItems="center" justifyContent="center">
            <Stack spacing={3} px={2} alignItems="center" textAlign="center" maxWidth="sm">
              <Typography variant="h5" maxWidth="sm">It looks like the document link has expired. Enter your email address and we'll send you a new one.</Typography>
              
              {errorMessage && 
                <Alert icon={false} severity="error" sx={{alignSelf: 'stretch', justifyContent: 'center'}}>
                  <Typography variant="h6">{errorMessage}</Typography>
                </Alert>
              }
              {success && 
                <Alert icon={false} severity="success" sx={{alignSelf: 'stretch', justifyContent: 'center'}}>
                  <Typography variant="h6">Your new link is on its way!</Typography>
                </Alert>
              }
              <Stack component="form" width="100%"  maxWidth="sm" alignSelf="center" spacing={2} onSubmit={(e) => { e.preventDefault(); sendNewEmailLink()}}>
                <TextField required fullWidth size="large" id="email-address-input" label="Email Address" variant="standard" onChange={(e) => setEmailAddress(e.target.value)}/>
                <Button variant="contained" type="submit" size="large" fullWidth disableElevation>Email My Link</Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      );
    }

    if (invalidLink) {
      return (
        <Stack flex={1}>
          <Header />
          <Box display="flex" flex={1} alignItems="center" justifyContent="center">
            <Stack spacing={3} px={2} alignItems="center" textAlign="center" maxWidth="sm">
              <Typography variant="h5" maxWidth="sm">The link you have requested is not valid.</Typography>
            </Stack>
          </Box>
        </Stack>
      );
    }

    return (
        <Stack flex={1}>
          <Header />
          <Box display="flex" flex={1}>
            {pdf && <iframe title={params.policyNumber} src={`/pdfjs-2.16.105-dist/web/viewer.html?file=${pdf}`} width="100%" height="100%" style={{borderLeft: 0, borderRight: 0, borderBottom: 0, borderTopWidth: 1}}></iframe>}
          </Box>
        </Stack>
    );
}