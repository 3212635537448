import { Routes, Route } from 'react-router-dom';
import Policy from './pages/Policy';
import NotFound from './pages/NotFound';

function App() {
  return (
    <Routes>
      <Route index path="/:policyNumber" element={<Policy />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
