import { Stack, Typography } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from "../component/Header";

export default function NotFound() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  
  return (
    <Stack width="100%" alignItems="stretch" justifyContent="center">
      <Header />
      <Stack alignItems="center" justifyContent="center" bgcolor="#fff" flex="1" height="100%" textAlign={isMobile ? 'center' : 'left'}>
        <Typography variant="h3" color="primary.main">Not Found</Typography>
        <Typography variant="h5" color="primary.main">The page you requested is not available.</Typography>
      </Stack>
    </Stack>
  );
}