import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Typography,
  Box,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import { getCarrierData } from "../api/policy";

const Header = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [carrierData, setCarrierData] = useState({});

  useEffect(() => {
    async function fetchCarrierData() {
      if (params.policyNumber) {
        const data = await getCarrierData(
          params.policyNumber,
          searchParams.get("id")
        );
        setCarrierData(data);
      }
    }

    fetchCarrierData();
  }, [params.policyNumber, searchParams]);

  return (
    <Box
      width="100%"
      p={1}
      display="flex"
      flexDirection={isMobile ? "column" : "row"}
      justifyContent="space-between"
      alignItems="center"
      bgcolor="primary.main"
    >
      <img
        src="https://images.squarespace-cdn.com/content/v1/62fa76612310b76e8cd82853/1660733962728-N9OZ4P3RT9P5M9A6U0JJ/201_Vertical+Insure-identity.png?format=500w"
        height={60}
        alt="Vertical Insure"
      />
      <Stack
        display="flex"
        alignSelf={isMobile ? "stretch" : "auto"}
        direction={isMobile ? "column" : "row"}
        spacing={1}
      >
        <Button
          variant="contained"
          disableElevation
          color="secondary"
          onClick={() => setOpen(true)}
        >
          File a Claim
        </Button>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            padding: "22px",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            File a Claim

              <IconButton
                aria-label="close"
                onClick={() => setOpen(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>

          </DialogTitle>
          
          <DialogContent dividers>
            {params.policyNumber && carrierData ? (
              <React.Fragment>
                <Typography variant="h6">
                  {carrierData.carrier_name}
                </Typography>
                {carrierData.claims_url && 
                  <Typography mt={2}>
                    To make a claim online or find out more information about the claims process go to <a href={carrierData.claims_url}>{carrierData.claims_url}</a>
                  </Typography>
                }
                <Typography fontWeight="bold" mt={2}>
                  Carrier Contact Information
                </Typography>
                {carrierData.claims_email && 
                  <Typography sx={{display: 'flex', alignItems: 'center'}}>
                    <MailOutlineIcon fontSize="small" sx={{marginRight: 1}}/> <a href={`mailto:${carrierData.claims_email}`}>
                      {carrierData.claims_email}
                    </a>
                  </Typography>
                }
                {carrierData.claims_phone && 
                  <Typography sx={{display: 'flex', alignItems: 'center'}}>
                    <PhoneIcon fontSize="small" sx={{marginRight: 1}}/> <a
                      style={{ textDecoration: "none" }}
                      href={`tel:${carrierData.claims_phone}`}
                    >
                      {carrierData.claims_phone}
                    </a>
                  </Typography>
                }
                
              </React.Fragment>
            ) : (
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Oops! This doesn't seem to be a valid link with a policy number!
              </Typography>
            )}
          </DialogContent>
        </Dialog>
        <Button
          href="https://www.verticalinsure.com/contact-support"
          variant="outlined"
          color="primaryLight"
          disableElevation
        >
          Contact Support
        </Button>
      </Stack>
    </Box>
  );
};

export default Header;
